import * as React from 'react'
import 'i18n/config'
import { useTranslation } from 'react-i18next'

import './styles.scss'

export const Error: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="error">
      <h1>{t('error.title')}</h1>
      <span>{t('error.description')}</span>
    </div>
  )
}
