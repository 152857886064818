{
  "title": "Verification",
  "providers": {
    "header": "Vahvista henkilöllisyytesi",
    "listHeader": "Valitse tunnistustapa",
    "missingProvider": "I do not see my Digital ID Provider"
  },
  "consent": {
    "shareInfo": "Olet jakamassa seuraavat tiedot Visa in kanssa",
    "notShareInfo": "Et jaa seuraavia tietoja",
    "consentConfirm": "Hyväksyn",
    "consentDeny": "En hyväksy",
    "firstAndLastName": "First and last name",
    "firstNameInitials": "Etunimien nimikirjaimet",
    "lastName": "Sukunimi",
    "lastNamePrefix": "Last name prefix",
    "firstName": "Etunimi",
    "dateOfBirth": "Syntymäaika",
    "emailAddress": "Sähköpostiosoite",
    "phoneNumber": "Puhelinnumero",
    "gender": "Sukupuoli",
    "finishId": "HETU"
  },
  "bankSelection": {
    "header": "Valitse pankkisi tai Mobiilivarmenne",
    "ftnHeader": "Valitse tunnistustapa",
    "description": "Kirjaudu omilla tunnuksillasi ja vahvista henkilöllisyytesi",
    "descriptionFtn": "Valitsemalla tunnistuksen toteuttajan allaolevasta valikosta (pankki tai mobiilivarmenne) hyväksyn sen, että Signicat tunnistusvälityspalvelu välittää tunnistusta varten henkilötietoni (hetu, nimi) pankilta tai mobiilivarmenteen tarjoajalta palvelun tarjoamista varten.",
    "descriptionFtnLink": "Tunnistusperiaatteet",
    "cancel": "Peruuta",
    "continue": "Jatka"
  },
  "error": {
    "title": "Tapahtui virhe",
    "description": "Valitettavasti et voi jatkaa toimintoa virheen takia"
  }
}