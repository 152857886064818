import * as React from 'react'
import '../../i18n/config'

import { observer } from 'mobx-react'
import './styles.scss'
import CheckoutLogo from "../../assets/checkout.svg";
export const Success: React.FC = observer(() => {
        return (
            <div className="success-page">
                <div className="topbar">
                    <div className="image-container">
                        <img src={CheckoutLogo}/>
                    </div>
                </div>
                <div className="payment-success">
                    <h1>Your payment authorization has been completed successfully</h1>
                    <div className="center-liner">
                        <div className="success-icon">
                            <svg width="124" height="125" viewBox="0 0 124 125" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <circle cx="62" cy="62.5" r="62" fill="url(#paint0_linear_675_15128)"/>
                                <path d="M42 66L53.5 77.5L82.5 48.5" stroke="white" stroke-width="6"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                                <defs>
                                    <linearGradient id="paint0_linear_675_15128" x1="34.9834" y1="10.1433" x2="92.9834"
                                                    y2="124.143" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#186AFF"/>
                                        <stop offset="1" stop-color="#711BC7"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div className="center-liner">
                        <span className="price">1.500 EUR</span>
                    </div>
                    <div className="center-liner">
                        <span className="company-name">Sample<span className="txt-green">Company</span></span>
                    </div>
                    <div className="center-liner">
                        <span className="date">10:23 11.09.2024</span>
                    </div>
                    <div className="center-liner">
                        <span className="transaction-id">TransactionID: <span
                            className="transaction-id-txt">123456789123456789</span></span>
                        d
                    </div>
                </div>
            </div>
        )
})
