import React, { createContext, useContext, useEffect } from 'react'

import { getQueryVariable } from 'methods/getQueryVariable'
import './styles.scss'
import { rootStore } from 'stores/Root.store'
import { Content } from 'components/content'
import { DeclineModal } from "components/modal/decline.modal";

export const StoreContext = createContext(rootStore)

export const App: React.FC = () => {
  const store = useContext(StoreContext)

  function changeTheme(themeId: string) {
    if (themeId === 'signicat') {
      store.AppState.setThemeId('signicat')
    }

    if (themeId === 'visa') {
      store.AppState.setThemeId('bootz')
    }

    if (themeId === 'sparTheme') {
      store.AppState.setThemeId('spar')
    }

    if (themeId === 'checkout') {
      store.AppState.setThemeId('checkout')
    }

  }

  useEffect(() => {
    const sgncBrand = getQueryVariable('sgncBrand')
    sgncBrand && store.AppState.setsgncBrand(sgncBrand === 'true')
    const path = window.location.pathname;
    if (path === "/success") {
      store.AppState.changePage("success")
    }
    const themeId = getQueryVariable('themeId')
    if (themeId) changeTheme(themeId)
  }, [])

  return (
    <StoreContext.Provider value={rootStore}>
      <div className="app">
        <Content/>
        <DeclineModal/>
      </div>
    </StoreContext.Provider>
  )
}
