{
  "title": "Verification",
  "providers": {
    "header": "Confirm Your Identity",
    "listHeader": "Choose your digital ID",
    "missingProvider": "I do not see my Digital ID Provider"
  },
  "consent": {
    "shareInfo": "You are choosing to pay the following amount:",
    "notShareInfo": "You are <u>not</u> sharing the following data:",
    "consentConfirm": "Approve",
    "consentDeny": "Decline",
    "firstAndLastName": "First and last name",
    "firstNameInitials": "First name initials",
    "lastName": "Last name",
    "lastNamePrefix": "Last name prefix",
    "firstName": "First name",
    "dateOfBirth": "Date of Birth",
    "emailAddress": "E-mail address",
    "phoneNumber": "Phone number",
    "gender": "Gender",
    "finishId": "Finnish personal identification numbers (HETU)."
  },
  "bankSelection": {
    "header": "Choose your bank",
    "ftnHeader": "Choose your method",
    "description": "Log in to your bank with iDIN and verify your identity",
    "descriptionFtn": "By selecting an ID provider in the list, I accept that Signicat transfers my personal data (personal identification code, name) to the service provider.",
    "descriptionFtnLink": "Identification principles",
    "cancel": "Back",
    "continue": "Next"
  },
  "error": {
    "title": "Error",
    "description": "Unfortunately, you cannot continue the process because of an error."
  },
  "modal": {
    "header": "Do you want to decline?",
    "desc": "Please choose one option:",
    "continue": "Continue",
    "cancel": "Cancel"
  }
}
