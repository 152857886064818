{
  "title": "Verification",
  "providers": {
    "header": "Bevestig je identiteit",
    "listHeader": "Kies uw digitale id-provider",
    "missingProvider": "I do not see my Digital ID Provider"
  },
  "consent": {
    "shareInfo": "Je staat op het punt de volgende gegevens te delen met Visa",
    "notShareInfo": "Je deelt de volgende gegevens <u>niet</u>",
    "consentConfirm": "Ik stem ermee in",
    "consentDeny": "Ik geef geen toestemming",
    "firstAndLastName": "First and last name",
    "firstNameInitials": "Initialen",
    "lastName": "Achternaam",
    "lastNamePrefix": "Tussenvoegsel(s)",
    "firstName": "Voornaam",
    "dateOfBirth": "Geboortedatum",
    "emailAddress": "E-mail addres",
    "phoneNumber": "Telefoonnummer",
    "gender": "Geslacht"
  },
  "bankSelection": {
    "header": "Kies je bank",
    "ftnHeader": "Kies je methode",
    "description": "Log in bij uw bank met iDIN en verifieer uw identiteit",
    "descriptionFtn": "By selecting an ID provider in the list, I accept that Signicat transfers my personal data (personal identification code, name) to the service provider.",
    "descriptionFtnLink": "Identification principles",
    "cancel": "Annuleren",
    "continue": "Doorgaan"
  },
  "error": {
    "title": "Fout",
    "description": "Helaas kun je het proces niet vervolgen in verband met een fout"
  }
}