import React from 'react';

import './styles.scss';

interface ModalInterface {
    children: React.ReactNode;
    modalVisible: boolean;
    setModalVisible: Function;
}

export const Modal: React.FC<ModalInterface> = ({ children, modalVisible, setModalVisible }: ModalInterface) => {
    return modalVisible ? (
        <div className="modal-overlay">
            <div className="modal-overlay-bg" onClick={(): void => setModalVisible(false)} />
            <div className="modal">
                <div className="modal-close" onClick={(): void => setModalVisible(false)}></div>
                <div className="modal--inner">{children}</div>
            </div>
        </div>
    ) : (
        <div />
    );
};
