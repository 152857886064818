import { action, makeAutoObservable, when } from 'mobx'
import { pages } from 'constants/pages'

export type Provider = {
  id: string
  countryCode: string
  countryName: string
  name: string
}

type Payment = {
  id: string
  amount: string
  currency: string
}

export class AppStateStore {
  rootStore
  activePage = ''
  providers = [] as Provider[]
  payment = {} as Payment
  selectedProvider = ''
  currentScreen = pages.pageConsent
  sgncBrand = true
  modalVisible = false
  themeId = 'bootz'

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound setActivePage(activePage: string): void {
    this.activePage = activePage
    this.changePage(activePage)
  }

  @action.bound changePage(page: string): void {
    this.currentScreen = { page }
  }

  @action.bound setProviders(providers: Provider[]): void {
    this.providers = providers
  }

  @action.bound setPayment(payment: Payment): void {
    this.payment = payment
  }

  @action.bound setSelectProvider(provider: string): void {
    this.selectedProvider = provider
  }

  @action.bound setsgncBrand(sgncBrand: boolean): void {
    this.sgncBrand = sgncBrand
  }

  @action.bound setModalVisible(visible: boolean): void {
    this.modalVisible = visible
  }

  @action.bound setThemeId(themeId: string): void {
    this.themeId = themeId
  }
}
