import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import axios from 'axios';

import { StoreContext } from '../App';
import { Modal } from './modal';
import {useTranslation} from "react-i18next";

export const DeclineModal: React.FC = observer(() => {
    const store = useContext(StoreContext);
    const { t } = useTranslation()
    const { modalVisible, themeId } = store.AppState;


    const handleContinue = async (): Promise<void> => {
        window.location.href = `${process.env.WEB_API_URL}/visa-payment-authorization/user-cancel`
    };

    const handleCancel = (): void => {
        store.AppState.setModalVisible(false);
    };

    const handleModalVisibility = (): void => {
        store.AppState.setModalVisible(false);
    };

    return (
        <Modal modalVisible={modalVisible} setModalVisible={handleModalVisibility}>
            <div className="modal--decline">
                <div className="line centered">
                    <h2>{t('modal.header')}</h2>
                    <p>{t('modal.desc')}</p>
                    <button className={`btn btn--primary btn--primary--${themeId}`} onClick={(): Promise<void> => handleContinue()}>
                        <span>{t('modal.continue')}</span>
                        {themeId === 'visa' ? <div className="btn--primary-arrow"></div> :""}
                    </button>
                    <button className={`btn btn--secondary btn--secondary--${themeId}`} onClick={(): void => handleCancel()}>
                        <span>{t('modal.cancel')}</span>
                    </button>
                </div>
            </div>
        </Modal>
    );
});
