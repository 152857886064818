import * as React from 'react'
import '../../i18n/config'
import { useTranslation } from 'react-i18next'
import { StoreContext } from 'components/App'
import { observer } from 'mobx-react'

import './styles.scss'
import axios from "axios";
import {useState} from "react";
import {Spinner} from "components/spinner/spinner";
import CheckoutLogo from "../../assets/checkout.svg";
export const Consent: React.FC = observer(() => {
  const { t } = useTranslation()
  const store = React.useContext(StoreContext)
  const { payment, themeId } = store.AppState
  const [loading, setLoading] = useState(true)

  const getPaymentDetails = async (): Promise<void> => {
        try {
            const response = await axios.get(`${process.env.WEB_API_URL}/visa-payment-authorization/payment-details`, { withCredentials: true })
            store.AppState.setPayment(response.data)
            setLoading(false)
        } catch (e) {
            store.AppState.setActivePage('error')
        }
    }

  const handleConsent = (): void => {
    window.location.href = `${process.env.WEB_API_URL}/visa-payment-authorization/redirect-to-auth`
  }

  const handleDoNotConsent = (): void => {
   store.AppState.setModalVisible(true)
  }

  React.useEffect(() => {
    getPaymentDetails()
  }, [])

  return (
      <div className="consent__container">
          {themeId === 'checkout' ?
          <div className={`consent__top consent__top--${themeId}`}>
              <div className={`provider-logo provider-logo-container`}>
                  <img src={CheckoutLogo} />
              </div>
          </div>
              : ""}
          <div className={`consent consent--${themeId}`}>
              <div className={`consent__banner consent__banner--${themeId}`}>
                  <div className={`provider-logo provider-logo--${themeId}`}/>
              </div>
              <div className="consent__data">
                  {loading ? (
                      <div className="block-centered">
                          <Spinner/>
                      </div>
                  ) : (
                      <div className="consent__data--content">
                          <p className="consent__data--description">{t('consent.shareInfo')}</p>
                          <p className="consent__data--amount">{payment.amount} {payment.currency}</p>
                          <ul>
                              <li>merchant: {themeId === "checkout" ? "checkout.com" : "Boozt Fashion AB"}</li>
                              <li>transactionID: {payment.id}</li>
                          </ul>
                      </div>
                  )
                  }
              </div>
              <div className="consent__data consent__data--not-shared">
                  {/*<p dangerouslySetInnerHTML={{ __html: t('consent.notShareInfo') }} />*/}
                  {/*{handleUserDataListNotToShare()}*/}
              </div>
              <div className="consent__action">
                  <button className={`btn btn--secondary btn--secondary--${themeId}`} onClick={(): void => handleDoNotConsent()}>
                      <span>{t('consent.consentDeny')}</span>
                  </button>
                  <button className={`btn btn--primary btn--primary--${themeId}`} onClick={(): void => handleConsent()}>
                      <span>{t('consent.consentConfirm')}</span>
                      {themeId === 'visa' ? <div className="btn--primary-arrow"></div> : ""}
                  </button>
              </div>
              <div className="consent__bottom">
                  <div className="powered-by">
                      <div className="foo-logo-sgn"/>
                      {themeId === 'visa' ? <div className="foo-logo-visa"/> : ""}
                  </div>
              </div>
          </div>
      </div>
  )
})
